<template>
        <!--General Information Tab start -->
        <div>
        <!-- <p class="mt-2 ml-3 font-weight-bold"> {{$t('teaGardenConfig.garden_id_no')}}: {{ formData.garden_id_no }}</p> -->
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-overlay :show="loader">
              <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
                <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Garden Registration No" vid="registration_no_en" rules="required">
                      <b-form-group
                        label-for="mobile"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('teaGardenPduService.garden_registration_no')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          v-model="datas.registration_no_en"
                          id="registration_no_en"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                <template v-if="$i18n.locale === 'en'">
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Tea Garden Name (En)" vid="tea_garden_name_en" rules="required">
                      <b-form-group
                        label-for="tea_garden_name_en"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('teaGardenConfig.tea_garden_name')}}<span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          v-model="datas.tea_garden_name_en"
                          id="tea_garden_name_en"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </template>
                <template v-else>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Tea Garden Name (Bn)" vid="tea_garden_name_bn" rules="required">
                      <b-form-group
                        label-for="tea_garden_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('teaGardenConfig.tea_garden_name')}}<span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          v-model="datas.tea_garden_name_bn"
                          id="tea_garden_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </template>
                  <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Registration No" vid="registration_no_en" rules="required">
                      <b-form-group
                        label-for="registration_no_en"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('teaGardenConfig.registration_no')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          v-model="formData.registration_no_en"
                          id="registration_no_en"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col> -->
                  <template v-if="$i18n.locale === 'en'">
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Garden Address (En)" vid="garden_address_en" rules="required">
                      <b-form-group
                        label-for="garden_address_en"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('teaGardenConfig.garden_address')}}<span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          v-model="datas.garden_address_en"
                          id="garden_address_en"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                 </template>
                  <template v-else>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Garden Address (Bn)" vid="garden_address_bn" rules="required">
                      <b-form-group
                        label-for="garden_address_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('teaGardenConfig.garden_address')}}<span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          v-model="datas.garden_address_bn"
                          id="garden_address_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  </template>
                 <template v-if="$i18n.locale === 'en'">
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Owner Name (En)" vid="owner_name_en" rules="required">
                      <b-form-group
                        label-for="owner_name_en"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('teaGardenConfig.owner_name')}}<span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          v-model="datas.owner_name_en"
                          id="owner_name_en"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                 </template>
                  <template v-else>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Owner Name (Bn)" vid="owner_name_bn" rules="required">
                      <b-form-group
                        label-for="owner_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('teaGardenConfig.owner_name')}}<span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          v-model="datas.owner_name_bn"
                          id="owner_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  </template>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Email" vid="email" rules="required|email">
                      <b-form-group
                        label-for="email"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('teaGardenConfig.garden_authority_email')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          v-model="datas.email"
                          id="email"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Garden Authority Mobile" vid="mobile" rules="digits:11|required">
                      <b-form-group
                        label-for="mobile"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('teaGardenConfig.garden_authority_mobile')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                         type="number"
                          v-model="datas.mobile"
                          id="mobile"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Garden Type" vid="garden_type" rules="required|min_value:1">
                      <b-form-group
                        slot-scope="{ valid, errors }"
                        label-for="garden_type">
                        <template v-slot:label>
                          {{ $t('teaGardenConfig.garden_type') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                              plain
                              v-model="datas.garden_type"
                              :options="getGardenTypeList"
                              id="garden_type"
                              :state="errors[0] ? false : (valid ? true : null)"
                              disabled
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                          </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <template v-if="$i18n.locale === 'en'">
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Garden History (En)" vid="garden_history_en">
                      <b-form-group
                        label-for="garden_history_en">
                        <template v-slot:label>
                          {{ $t('teaGardenConfig.garden_history') }}
                        </template>
                        <b-form-input
                          id="garden_history_en"
                          v-model="datas.garden_history_en"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  </template>
                 <template v-else>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Garden History (Bn)" vid="garden_history_bn">
                      <b-form-group
                        label-for="garden_history_bn">
                        <template v-slot:label>
                          {{ $t('teaGardenConfig.garden_history') }}
                        </template>
                        <b-form-input
                          id="garden_history_bn"
                          v-model="datas.garden_history_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </template>
                  <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Visitor Name" vid="visitor_name_en" rules="required">
                      <b-form-group
                        label-for="visitor_name_en"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('teaGardenPduService.visitor_name')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          v-model="formData.visitor_name_en"
                          id="visitor_name_en"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Visitor Designation" vid="owner_name_bn" rules="required">
                      <b-form-group
                        label-for="owner_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('teaGardenPduService.visitor_designation')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          v-model="formData.owner_name_bn"
                          id="owner_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Visitor Mobile no" vid="owner_name_bn" rules="required">
                      <b-form-group
                        label-for="owner_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('teaGardenPduService.visitor_mobile_number')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          v-model="formData.owner_name_bn"
                          id="owner_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Visitor Email no" vid="owner_name_bn" rules="required">
                      <b-form-group
                        label-for="owner_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('teaGardenPduService.visitor_email_no')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          v-model="formData.owner_name_bn"
                          id="owner_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col> -->
                  <template v-if="datas.land_lease_info">
                   <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Lease Start Date" vid="lease_start_date" rules="required">
                      <b-form-group
                        slot-scope="{ valid, errors }"
                        label-for="lease_start_date">
                        <template v-slot:label>
                          {{ $t('teaGardenConfig.lease_start_date') }} <span class="text-danger">*</span>
                        </template>
                        <date-picker
                          id="lease_start_date"
                          v-model="datas.land_lease_info.lease_start_date"
                          class="form-control"
                          :placeholder="$t('globalTrans.select_date')"
                          :class="errors[0] ? 'is-invalid' : ''"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                        >
                        </date-picker>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Lease End Date" vid="lease_end_date" rules="required">
                      <b-form-group
                        slot-scope="{ valid, errors }"
                        label-for="lease_end_date">
                        <template v-slot:label>
                          {{ $t('teaGardenConfig.lease_end_date') }} <span class="text-danger">*</span>
                        </template>
                        <date-picker
                          id="lease_end_date"
                          v-model="datas.land_lease_info.lease_end_date"
                          class="form-control"
                          :placeholder="$t('globalTrans.select_date')"
                          :class="errors[0] ? 'is-invalid' : ''"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                        >
                        </date-picker>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Lease Duration" vid="lease_duration" rules="required">
                      <b-form-group
                        label-for="lease_duration"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('teaGardenConfig.lease_duration')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          type="text"
                          v-model="datas.land_lease_info.lease_duration"
                          id="lease_duration"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Reg. Lease Deed No." vid="reg_lease_deed_no" rules="required">
                      <b-form-group
                        label-for="reg_lease_deed_no"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('teaGardenConfig.reg_lease_deed_no')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          type="number"
                          v-model="datas.land_lease_info.reg_lease_deed_no"
                          id="shop_no"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Lease Deed Date" vid="lease_deed_date" rules="required">
                      <b-form-group
                        class="row"
                        label-for="lease_deed_date"
                        slot-scope="{ valid, errors }">
                        <template v-slot:label>
                          {{ $t('teaGardenConfig.lease_deed_date') }}  <span class="text-danger">*</span>
                        </template>
                        <date-picker
                          id="lease_deed_date"
                          v-model="datas.land_lease_info.lease_deed_date"
                          class="form-control"
                          :placeholder="$t('globalTrans.select_date')"
                          :class="errors[0] ? 'is-invalid' : ''"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                        >
                        </date-picker>
                        <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </template>
                 <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Visit Time Delegate name (En)" vid="visit_time_del_name_en" rules="required">
                      <b-form-group
                        label-for="visit_time_del_name_en">
                        <template v-slot:label>
                          {{ $t('teaGardenPduService.visit_time_dele_name') }} {{ $t('globalTrans.en')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="visit_time_del_name_en"
                          v-model="formData.visit_time_del_name_en"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Visit Time Delegate name (En)" vid="visit_time_del_name_bn" rules="required">
                      <b-form-group
                        label-for="visit_time_del_name_bn">
                        <template v-slot:label>
                          {{ $t('teaGardenPduService.visit_time_dele_name') }} {{ $t('globalTrans.bn')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="visit_time_del_name_bn"
                          v-model="formData.visit_time_del_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                      <ValidationProvider name="Visit Date" vid="visit_date" rules="required">
                        <b-form-group
                            label-for="visit_date"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('teaGardenBtriService.visit_date')}}
                            </template>
                            <date-picker
                            id="date"
                            v-model="formData.visit_date"
                            class="form-control"
                            :placeholder="$t('globalTrans.select_date')"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :class="errors[0] ? 'is-invalid' : ''"
                            >
                            </date-picker>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Delegate Mobile Nubmer" vid="delegate_mobile_no" rules="digits:11|required">
                      <b-form-group
                        label-for="mobile"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('teaGardenPduService.delegate_mobile_no')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                         type="number"
                          v-model="formData.delegate_mobile_no"
                          id="delegate_mobile_no"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        </b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Delegate E-mail" vid="delagate_email">
                      <b-form-group
                        label-for="delagate_email">
                        <template v-slot:label>
                          {{ $t('teaGardenPduService.delegate_email') }}
                        </template>
                        <b-form-input
                          type="email"
                          id="delagate_email"
                          v-model="formData.delagate_email"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Attachement" vid="attachement">
                      <b-form-group
                        slot-scope="{ valid, errors }"
                        label-for="attachement">
                        <template v-slot:label>
                          {{ $t('teaGardenPduService.m_e_report_upload') }}
                        </template>
                        <b-input-group>
                        <b-form-file
                          id="attachement"
                          name="attachement"
                          v-model="formData.attachement"
                          @change="onChange($event, 'attachement')"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-file>
                        <!-- <b-input-group-append v-if="formData.attachement">
                          <a target="_blank" :href="teaGardenServiceBaseUrl+formData.attachement" style="padding: 2px 10px;" class="btn btn-primary btn-sm" ><i class="ri-download-2-line"></i></a>
                        </b-input-group-append> -->
                        </b-input-group>
                        <span class="text-danger">{{ errMessage.attachement }}</span>
                        <div class="d-block invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Delegate Desingation (En)" vid="delegate_desingation_en">
                      <b-form-group
                        label-for="delegate_desingation_en">
                        <template v-slot:label>
                          {{ $t('teaGardenPduService.delegate_designation') }} {{ $t('globalTrans.en') }}
                        </template>
                        <b-form-textarea
                          id="delegate_desingation_en"
                          v-model="formData.delegate_desingation_en"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-textarea>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Delegate Desingation (Bn)" vid="delegate_desingation_bn">
                      <b-form-group
                        label-for="delegate_desingation_bn">
                        <template v-slot:label>
                          {{ $t('teaGardenPduService.delegate_designation') }} {{ $t('globalTrans.bn') }}
                        </template>
                        <b-form-textarea
                          id="delegate_desingation_bn"
                          v-model="formData.delegate_desingation_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-textarea>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row class="text-right">
                  <b-col>
                    <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.save') }}</b-button>
                    <b-button class="btn-sm btn btn-danger"  @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-overlay>
          </ValidationObserver>
        </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { masterGardenProfileList, prepareMEGardenReportUpdate } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/route-based-list'
export default {
  name: 'prepareReport',
  props: ['id'],
  mixins: [ModalBaseMasterList],
  components: {
      ValidationObserver,
      ValidationProvider
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      loader: false,
      errMessage: {
        attachement: ''
      },
      item: '',
      datas: {},
      formData: {
        visit_time_del_name_en: '',
        visit_time_del_name_bn: '',
        delegate_mobile_no: '',
        visit_date: '',
        delagate_email: '',
        delegate_desingation_en: '',
        delegate_desingation_bn: '',
        attachement: []
      }
    }
  },
  created () {
    if (this.id) {
      this.getGardenProfileInfo()
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    getGardenTypeList: function () {
      return this.$store.state.TeaGardenService.commonObj.gardenType
    }
  },
  watch: {
  },
  methods: {
    onChange (e, type) {
      const selectedFile = e.target.files[0]
      if (selectedFile.size > 1024 * 1024) {
        e.preventDefault()
        this.errMessage[type] = this.$t('dealer.max_upload')
      } else {
        this.errMessage[type] = ''
        const reader = new FileReader()
        reader.onload = (event) => {
          this.formData[e.target.name] = event.target.result
        }
        reader.readAsDataURL(selectedFile)
      }
    },
    async saveUpdate () {
      this.loader = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: true }
      result = await RestApi.putData(teaGardenServiceBaseUrl, `${prepareMEGardenReportUpdate}/${this.id}`, this.formData)
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-5')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loader = false
    },
    async getGardenProfileInfo () {
      this.loader = true
      const result = await RestApi.getData(teaGardenServiceBaseUrl, `${masterGardenProfileList}/${this.id}`)
      if (result.success) {
        this.datas = result.data
      }
      this.loader = false
    }
  }
}
</script>
<style scoped>
  .p_heading{
    font-weight: bold;
  }
</style>

<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGardenPduService.prepare_m_e_report') }} {{ $t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Garden Name" vid="garden_id">
                <b-form-group
                    label-for="garden_id"
                    slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{$t('teaGardenConfig.garden_name')}}
                </template>
                <v-select
                    id="garden_id"
                    v-model="search.garden_id"
                    :reduce="op => op.value"
                    :options="gardenList"
                    label="text"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :placeholder="$t('globalTrans.select')"
                    >
                    </v-select>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
                </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4">
                <ValidationProvider name="Tentative Date" vid="tentative_date">
                <b-form-group
                    label-for="tentative_date"
                    slot-scope="{ valid, errors }"
                >
                    <template v-slot:label>
                    {{$t('teaGardenBtriService.tentative_date')}}
                    </template>
                    <date-picker
                    id="date"
                    v-model="search.tentative_date"
                    class="form-control"
                    :placeholder="$t('globalTrans.select_date')"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :class="errors[0] ? 'is-invalid' : ''"
                    >
                    </date-picker>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
                </ValidationProvider>
            </b-col>
          <b-col sm="4">
            <b-button size="sm" variant="primary" class="mt-20" @click.prevent="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenPduService.prepare_m_e_report') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <router-link class="btn-add" :to="{ name: 'tea_garden_service.pdu.prepare_m_e_report' }"><i class="ri-arrow-go-back-fill"></i> {{ $t('globalTrans.back') }}</router-link>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(application_no)="data">
                    <span v-if="data.item.application">{{data.item.application.application_no}}</span>
                  </template>
                  <template v-slot:cell(status)="data">
                      {{ getStatusName(data.item.status) }}
                  </template>
                  <template v-slot:cell(garden_name)="data">
                      {{ getGardenName(data.item.garden_id) }}
                  </template>
                  <template v-slot:cell(tentative_date)="data">
                      <span v-if="data.item.detail">{{data.item.detail.tentative_date | dateFormat}}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <!-- <b-button class="moc-action-btn moc-forward-btn" v-if="data.item.status === 1" :title="$t('teaGardenPduService.prepare_m_e_report')" v-b-modal.modal-5 @click="prepareGarden(data.item)">
                      <i class="ri-shield-check-line"></i>
                    </b-button> -->
                    <b-button class="moc-action-btn moc-approve-btn" v-if="data.item.status === 2" :title="$t('globalTrans.approve')" @click="prepareGardenApprove(data.item)">
                      <i class="ri-shield-check-line"></i>
                    </b-button>
                    <!-- <b-badge v-if="data.item.status === 3" variant="success">{{ $t('globalTrans.complete')}}</b-badge> -->
                    <!---Attachment-->
                    <a target="_blank" :href="teaGardenServiceBaseUrl + (isImage(data.item.attachement) ? 'storage/' : '') + data.item.attachement" v-if="data.item.status === 3 && data.item.attachement" class="moc-action-btn btn-primary" :title="$t('globalTrans.attachment')">
                      <i class="ri-file-line"></i>
                    </a>
                     <router-link v-if="data.item.status === 1 && (data.item?.ins_gen_info?.status === 2 || !data.item?.ins_gen_info?.id)" class="moc-action-btn moc-view-btn" :to="{
                      name:'tea_garden_service.pdu.prepare_m_e_form',
                      params: { id: data.item.id }
                     }"
                     :title="$t('teaGardenPduService.prepare_m_e_report')">
                       <i class="ri-file-edit-fill"></i>
                      </router-link>
                       <b-button class="moc-action-btn moc-forward-btn" v-if="data.item?.ins_gen_info?.status === 1" :title="$t('globalTrans.pdf')" @click="pdfExport(data.item.id)">
                      <i class="ri-file-pdf-line"></i>
                      </b-button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <!-- visit garden mdoal -->
    <b-modal id="modal-5" size="lg" :title="$t('teaGardenPduService.prepare_m_e_report')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
     <prepareReport :id="item"  :key="prepareItemId"/>
    </b-modal>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { prepareMEgardenInfoList, regularGarInsApprove, evaluationReportAppInfo } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/route-based-list'
import prepareReport from './prepareReport.vue'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    prepareReport
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        search: {
          garden_id: '',
          limit: 10,
          status: this.$route.query.status ? this.$route.query.status : 0
        },
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '8%' } },
        { labels: 'teaGardenService.application_id', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
        { labels: 'teaGardenConfig.garden_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '25%' } },
        // { labels: 'teaGardenBtriService.assign_person', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '25%' } },
        { labels: 'teaGardenBtriService.tentative_date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '15%' } },
        { labels: 'globalTrans.status', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '12%' } },
        { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 7, thStyle: { width: '12%' } }
      ],
      items: [],
      item: [],
      prepareItemId: [],
      actions: {
        edit: true,
        toogle: true,
        delete: false
      }
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    currentLocal () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'application_no' },
          { key: 'garden_name' },
          { key: 'tentative_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'application_no' },
          { key: 'garden_name' },
          { key: 'tentative_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    cloneNameList () {
      return this.$store.state.TeaGardenService.commonObj.masterCloneNameList.filter(item => item.status === 1)
    },
    visitGardenFormTitle () {
      return this.$t('teaGardenBtriService.visit_application')
    },
    statusList () {
        const arr = [
          { value: 1, text: this.$t('globalTrans.pending'), text_en: this.$t('globalTrans.pending', 'en'), text_bn: this.$t('globalTrans.pending', 'bn') },
          { value: 2, text: this.$t('teaGardenPduService.confirmed'), text_en: this.$t('teaGardenPduService.confirmed', 'en'), text_bn: this.$t('teaGardenPduService.confirmed', 'bn') },
          { value: 3, text: this.$t('globalTrans.approve'), text_en: this.$t('globalTrans.approve', 'en'), text_bn: this.$t('globalTrans.approve', 'bn') }
        ]
        return arr
    },
    gardenList () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    }
  },
  methods: {
    prepareGardenApprove (item) {
      window.vm.$swal({
      title: window.vm.$t('teaGardenPduService.approve_status'),
      showCancelButton: true,
      confirmButtonText: window.vm.$t('globalTrans.yes'),
      cancelButtonText: window.vm.$t('globalTrans.no'),
      focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.prepareApproveSubmit(item)
        }
      })
    },
    prepareApproveSubmit (item) {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(teaGardenServiceBaseUrl, `${regularGarInsApprove}/${item.id}`).then(response => {
          if (response.success) {
            window.vm.$toast.success({
              title: window.vm.$t('globalTrans.success'),
              message: window.vm.$t('globalTrans.update_msg'),
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
    },
    prepareGarden (item) {
      this.item = item.garden_id
      this.prepareItemId = item.id
    },
    details (item) {
      this.items = item
    },
    edit (item) {
      this.item = item.garden_id
      this.prepareItemId = item.id
    },
    searchData () {
      this.loadData()
    },
    getStatusName (status) {
      const obj = this.statusList.find(item => item.value === parseInt(status))
      return this.currentLocal === 'en' ? obj?.text_en : obj?.text_bn
    },
    getGardenName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === id)
      return this.currentLocal === 'en' ? obj?.text_en : obj?.text_bn
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, prepareMEgardenInfoList + '/' + this.$route.params.id, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          return Object.assign({}, item, { serial: index })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async pdfExport (appDetailId) {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, appDetailId: appDetailId })
          const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 17)
          if (service !== undefined) {
            if (service.office_type_id) {
              params.office_type_id = service.office_type_id
            }
            if (service.office_id) {
              params.office_id = service.office_id
            }
          }

        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, evaluationReportAppInfo, params)
      var blob = new Blob([result], {
            type: 'application/pdf'
        })
        this.loading = false
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    isImage (path) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
    }
  }
}
</script>
